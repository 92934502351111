.eula {
    @apply flex-1 overflow-scroll;
}

.eula ul {
    list-style: disc;
    padding-left: 1.25rem;
}

.eula ol {
    list-style: decimal;
    padding-left: 1.25rem;
}

.eula ol ol {
    list-style: lower-alpha;
}

.eula p {
    @apply mb-4;
}