@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

html, body, #root {
    height: 100%;
}

:root {
    --color-primary: 28 100 242;
    --color-primary-dark: 26 86 219;
    --color-primary-light: 164 202 254;
}